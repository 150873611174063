import React from "react";
import { newRelicWrapper } from "@shared/utils/Errors";
import { windowExists } from "@shared/utils/WebBrowser";
import { isDevelopmentMode } from "@shared/utils/Debug";

// try to use culture resolution to send the user to the correct index page
export default function NotFoundPage() {
    if (windowExists()) {
        if (!isDevelopmentMode()) {
            newRelicWrapper.logPageAction("studio-404");
        }
        // if our hostname contains vistaprint, then we want our base to be /studio, not /studio/five/{locale}
        // the latter is only true on localhost and hitting cloudfront directly
        // the first case is a bit tricky though, as it could be /studio/fr.
        // i think for now, given that belgium is our only multi-locale, i don't care
        if (/vistaprint/.test(window.location.hostname)) {
            window.location.href = `/studio/${window.location.search}`;
        } else {
            // just a best guess
            window.location.href = `/studio/five/en-ie/${window.location.search}`;
        }
    }

    return <div></div>;
}

NotFoundPage.displayName = "NotFoundPage";
